import './App.css';
import HomePageLayout from './layouts/HomePageLayout';
import { Toaster } from 'react-hot-toast';

const App = () => (
  <>
    <HomePageLayout />
    <Toaster />
  </>
)



export default App;
