import { data as getData } from "../lib/helper"


const data = getData();
const renderAboutImgRow = (first, second, third) => (
    <div className="row">
        <div className="col-md-4 img-wrapper">
            <div className="img-container">
                <img src={first.img} alt="bgImg" />
                <div className="overlay">
                    <h1>{first.label}</h1>
                    <p>{first.info}</p>
                </div>
            </div>
        </div>
        <div className="col-md-4 img-wrapper">
            <div className="img-container">
                <img src={second.img} alt="bgImg" />
                <div className="overlay">
                    <h1>{second.label}</h1>
                    <p>{second.info}</p>
                </div>
            </div>
        </div>
        <div className="col-md-4 img-wrapper">
            <div className="img-container">
                <img src={third.img} alt="bgImg" />
                <div className="overlay">
                    <h1>{third.label}</h1>
                    <p>{third.info}</p>
                </div>
            </div>
        </div>
    </div>
)

const About = () => (
    <div className="about">

        <div className="about-heading">
            <h1 id="about">About Us</h1>
        </div>

        <div className="about-container container">
            {renderAboutImgRow(data[0], data[1], data[2])}
            {renderAboutImgRow(data[3], data[4], data[5])}
        </div>

    </div>
)

export default About
