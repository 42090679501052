import img_1 from "../assets/img1.jpg"
import img_2 from "../assets/img2.jpg"
import img_3 from "../assets/img3.jpg"
import img_4 from "../assets/img4.jpg"
import img_5 from "../assets/img5.jpg"
import img_6 from "../assets/img6.jpg"

export const data = () => [
    {
        img: img_5,
        label: "Metal Recycling",
        info: `We recycle ferrous and non-ferrous metals that can be a significant revenue stream to your business`
    },
    {
        img: img_2,
        label: "Metal Production",
        info: `Unlike most waste management services we process, sort and clean your metal waste, 
            this waste is then sold into domestic mills and large foundries. `
    },
    {
        img: img_1,
        label: "Metal Processing",
        info: `Our in-house overband conveyor system teamed with vibrating hoppers and 
            wash trommels allow us to process, clean, 
            and separate metal waste for other waste management companies`
    },
    {
        img: img_3,
        label: "Metal Trading",
        info: `We buy and sell all ferrous and non-ferrous metal waste. Our customers 
            include manufacturers, metal fabricators, domestic mills, plumbers, electricians, 
            roofers and vehicle mechanics`
    },
    {
        img: img_4,
        label: "Metal Collection",
        info: `We can supply and deliver commercial skips, collect metal waste from your place of work or you can 
        visit our waste management facility to drop off your metal waste`
    },
    {
        img: img_6,
        label: "Metal Appearance",
        info: `We improve the performance and appearance of metal parts. Whether you're looking for quality 
            anodizing specialities, coating, or another solution, our experienced team is here to help. `
    },
] 
