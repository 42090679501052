import React from 'react'
import Copyright from '../components/Copyright'
import Footer from '../components/Footer'
import Separator from '../components/Separator'
import TopNav from '../components/TopNav'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Homepage from '../pages/Homepage'

const HomePageLayout = () => (
    <>
        <TopNav />
        <Homepage />
        <Separator />
        <About />
        <Separator />
        <Contact />
        <Footer />
        <Copyright />
    </>
)


export default HomePageLayout
