import bgImg from "../assets/bgImg.jpg";
import down from "../assets/down.gif";

function Homepage() {
    return (
        <div id="/" className="homepage">
            <img src={bgImg} alt="bgImg" />
            <div className="homepage-overlay">
                <div className="outer">
                    <h1>We are Indigo UK Trading Ltd</h1>
                    <h2>Ready to meet your <span className="metal">metal</span> needs</h2>
                </div>
            </div>

            <a href="#about">
                <img src={down} alt="down" />

            </a>
        </div>
    )
}

export default Homepage;
