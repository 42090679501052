import location from "../assets/location.png"
import phone from "../assets/phone.png"

const Footer = () => (
    <div className="footer">
        <div className="address">
            <img src={location} alt="location" />
            <h4>Unit 4, The Former Pump House Station Road, 
                Wath-Upon-Dearne, Rotherham, England, S63 7DQ</h4>
        </div>
        <div className="phone">
            <img src={phone} alt="phone" />
            <h4>01709925185</h4>
        </div>

    </div>
)

export default Footer
