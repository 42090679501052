
import brandLogo from '../assets/blackLogo.png'

const closeDropDown = (e) => {
    e.preventDefault();
    const element = document.getElementById("navDropDown");
    element.click();
}

const renderNavItems = () => (
    <div className="collapse navbar-collapse" id="navbarScroll">
        <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll" >
            <li className="nav-item" >
                <a className="nav-link" href="#">Home</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href='#about'>About</a>
            </li>

            <li className="nav-item" >
                <a className="nav-link" href="#contact">Contact</a>
            </li>
        </ul>
    </div >
)

const TopNav = () => (
    <nav className="top">
        <div className="top_nav">

            <div className="top_nav-logo">
                <img src={brandLogo} alt="brandLogo" />
            </div>

            <div className="top_nav-navbar">
                <a className="navbar_item" href="#">Home</a>
                <a className="navbar_item" href="#about">About</a>
                <a className="navbar_item" href="#contact">Contact</a>
            </div>

        </div>
    </nav>
)


export default TopNav
